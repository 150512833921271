@import 'assets/styles/variables';

.applicationSidebar {
  width: 80px;
  background-color: $white-secondary;

  & > div {
    width: 100%;
    text-align: center;
  }
}

.companyLogo {
  margin-top: 30px;
  margin-bottom: 65px;
}
