.menu {
  list-style: none;
  padding: 0;

  .menu__item>a {
    font-size: 16px;
    color: $black;
  }

  .menu__item {

    &.active a,
    &:hover a {
      cursor: pointer;
      color: $primary;
    }
  }
}

.submenu {

  // Plus button
  .plus-button {
    float: right;
  }

  .submenu__toggle {
    border: none;
    background-color: transparent;
    position: relative;
    font-size: 15px;

    &::after {
      content: "\e5cf";
      font-family: "Material Symbols Outlined", "Material Icons";
      font-size: 20px;
      font-weight: 400;
      color: #8A9099;
      position: absolute;
      top: 12px;
      right: 30px;
      transition: transform 0.3s ease-in-out;
      transform: rotate(-90deg);
    }

    &:hover {
      background-color: #F8F8F8;
      font-size: 16px;
    }

    &[aria-expanded="true"] {
      background: #F8F8F8;
      color: $dark !important;

      &::after {
        transform: rotate(0deg);
      }
    }

    &.submenu__toggle--title-only {
      &:after {
        display: none;
      }

      >*+span {
        line-height: 18px;
        margin-left: 10px;
      }
    }

    &.submenu__toggle--with-icon {
      display: flex !important;
      align-items: flex-end;

      >*+span {
        line-height: 18px;
        margin-left: 10px;
      }
    }
  }

  .submenu__menu {
    list-style: none;
    background-color: $white;
    padding-left: 30px;
    color: $body-color;

    &.no-styles {
      li {

        &::before {
          display: none;
          ;
        }
      }
    }

    &.user-list {
      padding-bottom: 15px;
      padding-left: 0;

      &.organization {
        .submenu__menu-item {
          margin-top: 0px;
          padding-top: 20px;
        }
      }

      li {
        padding-left: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e9e9e9;

        >div {
          gap: 35px;
        }

        &::before {
          display: none;
          ;
        }

        &.is-active {
          background-color: $white;
          ;
        }
      }

      .details {
        font-size: 14px;
        display: flex;
        flex-direction: column;

        .name {
          color: $headings-color;
        }

        .description {
          color: #595F69;
        }
      }

      .description {
        font-size: 12px;
      }
    }

    li {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: -30px;
        top: -30px;
        display: block;
        height: 42px;
        width: 20px;
        border-style: dashed;
        border-color: #8A9099;
        border-top-width: 0;
        border-left-width: 2px;
        border-right-width: 0;
        border-bottom-width: 2px;
      }

      &:first-child {
        .submenu__menu-item {
          margin-top: 10px;
        }
      }
    }

    .submenu__menu-item {
      display: grid;
      grid-template-columns: 26px auto 20px;
      color: $black;
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 30px;
      gap: 15px;

      .submenu__menu-item-icon {
        text-align: center;
      }

      &.active {
        font-weight: 600;
      }
    }
  }

  &.submenu--simple {
    .submenu__toggle {
      display: flex;
      font-size: 1rem;
      justify-content: space-between;
      padding: 20px 20px 20px 46px;

      &::after {
        top: 16px;
        left: 14px;
        right: auto;
      }

      &[aria-expanded="true"] {
        background: #F8F8F8;
        color: $dark !important;
        font-weight: 600;

        &::after {
          color: #3F434A;
        }
      }
    }

    .submenu__menu {
      padding-left: 50px;

      .submenu__menu-item {
        display: block;
        font-size: 0.9375rem;
        margin-top: 0;

        a,
        button {
          padding: 16px 0;
          width: 100%;
          text-align: left;
        }

        a {
          display: block;
        }

        button {
          border: none;
          background-color: transparent;
        }

        &:hover,
        &.active {

          a,
          button {
            font-weight: 500;
          }
        }

        &::before {
          display: none;
        }
      }
    }
  }
}

.floating-menu {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  box-sizing: border-box;
  width: 0px;
  border: 1px solid #E8E9EB;
  padding: 12px 0;
  opacity: 0;
  z-index: -1;
  transition: opacity .3s ease-in-out, width .3s ease-in-out;

  .floating-menu__separator {
    background-color: #E8E9EB;
    height: 1px;
    width: 100%;
    margin: 12px 0;
    opacity: 0;
  }

  .floating-menu__item {
    display: flex;
    align-items: center;
    padding: 12px 28px;
    color: $darkgray;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;

    &>*+span {
      margin-left: 16px;
    }

    &:hover {
      background-color: #F8F8F8;
    }
  }

  &.open {
    width: 224px;
    opacity: 1;
    z-index: 2;

    .floating-menu__item {
      opacity: 1;
      display: flex;
    }

    .floating-menu__separator {
      opacity: 1;
    }
  }
}

.floating-menu__trigger {
  background-color: transparent;
  padding: 0;
  border: none;
}

.tooltip {
  background-color: $primary;
  color: $white;
  font-size: 13px;
  padding: 10px 15px;
  border-radius: 10px 10px 10px 0;
  max-width: 350px;
}

.tooltip-arrow {
  position: absolute;
  display: block;
  left: 0;
  width: auto;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-right-width: 0px;
    border-left-width: 15px;
    border-bottom: 14px solid $primary;
  }
}
