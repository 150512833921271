.breadcrumb {
  font-weight: 500;
  font-size: 1.25rem;

  .breadcrumb-item {
    text-transform: capitalize;

    a {
      color: $secondary;
    }
  }
}
