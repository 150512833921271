.btn-close-wrapper {
  width: 35px;
  height: 35px;
  background-color: #F7F8F9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-close {
    margin: 0;
    padding: 8px;
    background-size: $btn-close-width $btn-close-height;
  }
}
