.dialog {
  .content {
    .title {
      font-weight: 500;
    }

    .header {
      .title {
        width: 100%;
      }
    }
  }

  &.withHeaderTabs {
    .header {
      min-height: 65px;
      padding-top: 0;
      padding-bottom: 0;

      .nav {
        .navItem {
          button {
            min-height: 65px;
            border-width: 0;
            border-bottom-width: 3px;
          }

          & + .navItem {
            margin-left: 40px;
          }
        }
      }
    }

    .body {
      padding-top: 1rem;
      padding-bottom: 1.25rem;
    }
  }
}
