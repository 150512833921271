@import 'assets/styles/variables';

.picker > * {
  color: $secondary;
  width: 100%;
  min-height: 48px;
  border: 1px solid #E8E9EB;
  padding: 12px 17px;
  font-size: 0.875rem;
  border-radius: 5px;
  border-top-width: 1px !important;

  &:hover {
    cursor: pointer;
    border-color: lighten($color: $orange, $amount: 0.5);
  }
}
