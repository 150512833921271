@import 'assets/styles/utils/animations';

.imagePreview {
  margin-top: 10px;

  img {
    object-fit: contain;
    width: 100%;
    max-width: 128px;
    height: auto;
    max-height: 128px;
    border: none;
    animation: fadeIn .6s ease;
  }
}
