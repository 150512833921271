@import 'assets/styles/variables';

.hasNotifications {
  position: relative;

  &::after {
    content: "";
    display: block;
    height: 8px;
    width: 8px;
    background-color: $primary;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: -5px;
  }
}
