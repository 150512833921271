.table {
  th {
    font-size: 14px;
  }

  &.table-narrow {
    th, td {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
