.roundFormCheck {
  position: relative;

  .roundCheckInput {
    opacity: 0;
    position: absolute;
  }

  .roundCheckLabel {
    display: flex;
    align-items: center;
    min-height: 30px;
  }

  .roundCheckInput + .roundCheckLabel {
    cursor: pointer;

    &::before, &::after {
      content: "";
      background-repeat: no-repeat;
      display: inline-block;
      height: 30px;
      width: 30px;
      transition: all 0.3s ease;
      transition-delay: 0.3s;
    }

    &::before {
      position: relative;
      background-image: url('../../../assets/images/icons/circle-uncheck.svg');
      margin-right: 5px;
    }

    &::after {
      position: absolute;
      left: 0;
      opacity: 0;
      background-image: url('../../../assets/images/icons/circle-check.svg');
      transform: rotate(360deg);
    }

    &:hover {
      filter: brightness(0.9);
    }
  }

  .roundCheckInput:checked + .roundCheckLabel {
    &::before {
      opacity: 0;
      transform: rotate(-360deg);
    }

    &::after {
      opacity: 1;
      transform: rotate(0deg);
    }
  }
}
