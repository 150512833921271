button:focus-visible {
  outline: 0;
}

a.btn {
  line-height: 1.9;

  &.fs-14 {
    line-height: 2.5;
  }
}

.btn:not(.btn-link, .btn-icon) {
  min-height: 40px;
  min-width: 180px;

  &.btn-gray {
    color: $body-color;
  }

  &.btn-primary {
    color: $btn-color;
  }

  &.btn-orange {
    color: $white;
  }

  &.btn--with-icon {
    display: flex;
    align-items: center;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  &.btn-icon {
    border-color: $orange;
    background-color: transparent;
    color: #95999F;

    span {
      font-size: 30px;
      font-weight: bold;
    }

    &:hover {
      background-color: lighten($orange, 30%);
    }
  }

  &.btn-link:hover {
    svg {
      path {
        transition: fill 0.15s ease-in-out;
        fill: var(--bs-btn-hover-color);
      }
    }
  }
}
