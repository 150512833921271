// Typography
$font-size-root: 16px;
$font-family-sans-serif: 'Poppins', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

// Headings
$h1-font-size: 4.5rem;
$h4-font-size: 1.74rem;
$h6-font-size: 1rem;
$headings-font-weight: 600;
$headings-color: #0D384D;

// Theme Colors
$body-bg: #FFFFFF;
$body-color: #4E5C65;

$primary: #58B0BE;
$secondary: #0D384D;
$info: #ADD2DB;
$lightgreen: #F2F8F9;
$darkgray: #595F69;
$dark: #0D384D;
$light: #F2F2F2;
$white: #FCFCFC;
$white-secondary: #F5F5F5;
$orange: #F37447;
$red: #E13F48;
$black: #222122;
$lightgray: #E8E9EB;
$gray: #DBDBDB;
$light-100: #F6F6F6;
$white-alternative: #F8F8F8;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'info': $info,
  'danger': #FD7972,
  'red': $red,
  'orange': $orange,
  'dark': $dark,
  'success': #4CBC9A,
  'warning': #E5C749,
  'light': $light,
  'light-100': $light-100,
  'lightgreen': $lightgreen,
  'gray': $gray,
  'light-medium': #A7AEB2,
  'lightgray': $lightgray,
  'white-secondary': $white-secondary,
  'default': $body-color
);

// Alerts
$alert-border-radius: 0;

// Links
$link-decoration: none;

// Inputs
$input-font-size: 1rem;
$input-border-radius: 0;
$input-line-height: 2.1;
$input-border-color: #D4D4D4;

// Input type checkbox
$form-check-input-width: 1.33em;
$form-check-input-border-radius: 0;
$form-check-label-cursor: pointer;

// Input type radio
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3.5' fill='#{$orange}'/></svg>");

// Input group
$input-group-addon-color: $primary;
$input-group-addon-bg: #FFFFFF;

// Labels
$form-label-font-size: 0.9375rem;
$form-label-font-weight: 500;

// Buttons
$btn-color: #FAFAFA;
$btn-font-weight: 500;
$btn-border-radius: 0;

// Cards
$card-border-width: 0;
$card-border-radius: 0;
$card-bg: #fff;

// Breadcrumbs
$breadcrumb-divider-color: $secondary;
$breadcrumb-active-color: $primary;
$breadcrumb-divider: quote(">");
$breadcrumb-margin-bottom: 0;

// Tables
$table-cell-padding-y: 1.2825rem;
$table-th-font-weight: 600;
$table-striped-bg: $white-secondary;

// Pagination
$pagination-border-width: 0;
$pagination-bg: rgba($primary, 0.1);
$pagination-border-radius: 0;
$pagination-margin-start: 1rem;

// Navs
$nav-link-padding-x: 0;
$nav-link-font-size: 0.875rem;
$nav-link-font-weight: 500;
$nav-link-color: $body-color;
$nav-link-hover-color: $primary;

// Navs - Tabs
$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 5px;
$nav-tabs-border-radius: 0;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent transparent $primary;

// Modals
$modal-inner-padding: 1.875rem;
$modal-content-border-radius: 0;

$modal-header-padding-y: 1rem;

// Close
$btn-close-width: 10px;
$btn-close-height: 10px;
$btn-close-color: #54607E;
$btn-close-opacity: 1;

// List group
$list-group-border-color: $lightgray;
$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.75rem;

// Badges
$badge-font-weight: 400;
$badge-padding-y: 5px;
$badge-padding-x: 15px;
$badge-border-radius: .4rem;
