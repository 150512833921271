.w-400 {
  width: 400px;
}

.max-w-700 {
  width: 100%;
  max-width: 700px;
}

.max-w-750 {
  width: 100%;
  max-width: 750px;
}

.min-h-100vh {
  min-height: 100vh;
}

.overflow-x-when-there-is-an-internal-sidebar {
  overflow-x: auto;
  max-width: calc(100vw - 380px);
}
