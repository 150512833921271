.AppCard {
  width: 300px;
  max-width: 100%;
  height: 200px;
  border: 1px solid #58B0BE;
  background-color: #FFFFFF;
  position: relative;

  .floatingMenuTrigger {
    background-color: transparent;
    padding: 0;
    border: none;
  }

  .header {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 1rem;
  }

  .body {
    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;

      img {
        margin-bottom: .75rem;
      }

      h5 {
        line-height: 36px;
        margin: 0;
        font-weight: 500;
      }
    }
  }

  &:hover {
    cursor: pointer;
    border-color: var(--bs-orange);
  }
}
