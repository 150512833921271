@import 'assets/styles/variables';

.applicationInternalSidebarItem {
  width: 100%;

  a {
    width: 100%;
  }

  & > a {
    display: block;
    height: 100%;
    padding: 15px 20px;
    color: $body-color;
  }

  &:not(.submenu):hover {
    cursor: pointer;
    background: #F8F8F8;

    & > a {
      color: $secondary;
      font-weight: 600;
    }
  }
}

.active {
  cursor: pointer;
  background: #F8F8F8;
  color: $secondary;
  font-weight: 600;
}
