@import 'assets/styles/variables';
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.formTag {
  display: flex;
  align-items: center;

  .tagInput {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: wrap;
    min-height: $input-height;
    transition: $input-transition;
    padding-top: $input-padding-y;
    padding-bottom: $input-padding-y;
    background-color: $input-bg;
    color: $input-color;
    border-style: solid;
    border-color: $input-border-color;
    border-width: $input-border-width;
    box-shadow: $input-box-shadow;
    border-radius: $input-border-radius;

    &.focus {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
  }

  .addTag {
    svg {
      fill: #58B0BE;
    }
  }
}

.tag {
  display: inline-flex;
  align-items: center;

  & > .tagRemove {
    border: none;
    background-color: transparent;
    height: 22px;
    width: 22px;
    padding: 2px;
    border-radius: 50%;
    transition: background-color .2s ease, box-shadow .2s ease;

    svg {
      vertical-align: unset;
      fill: #4E5C65;
      height: 100%;
      width: auto;
    }

    &:hover {
      background-color: rgb(248, 248, 248);
      box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    }
  }

  & + .tag {
    margin-left: 2px;
  }
}

.internalInput {
  display: inline-block;
  border: none;

  &:focus, &:focus-visible {
    border: none;
    outline: none;
  }
}
