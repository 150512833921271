@import 'assets/styles/variables';

.applicationSidebarItem {
  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;

    path {
      fill: $secondary;
    }

    img {
      width: 27px;
      height: auto;
    }

    &:hover {
      cursor: pointer;
      background-color: rgba($primary, 0.1);
    }
  }
}

.active {
  a {
    background-color: rgba($primary, 0.1);
  }
}
