@import 'assets/styles/variables';
@import 'assets/styles/utils/animations';

.formImage {
  position: relative;
  max-width: 96px;

  .formImageEdit, .formImageRemove {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 8px 4px;
    margin: 0 3px;
    border: none;
    background-color: transparent;

    svg {
      fill: #ffffff;
    }

    &:hover {
      svg {
        fill: $primary;
      }
    }
  }

  .formImageEdit {
    right: 26px;
  }

  .formImageRemove {
    right: 0;
  }
}

.formImagePreview {
  background-color: #10141A;
  opacity: 0.64;
  height: 96px;
  width: 96px;

  img {
    object-fit: cover;
    height: 96px;
    width: 96px;
  }
}

.formImagePreview img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  animation: fadeIn .6s ease;
}
