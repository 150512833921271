.was-validated .form-control:invalid ~ .input-group-text {
  &.input-group-text--password-visibility {
    border-color: var(--bs-red);
  }
}

.was-validated .form-control:valid ~ .input-group-text {
  &.input-group-text--password-visibility {
    border-color: var(--bs-green);
  }
}

.input-group-text {
  &.input-group-text--password-visibility {
    color: $primary;

    &:hover {
      cursor: pointer;
      color: darken($primary, 15%);
    }
  }
}

.input-group {
  &.input-group--gray {
    input, span {
      background-color: #F8F8F8;
    }
  }
}
