@import 'assets/styles/variables';
@import 'assets/styles/utils/animations';

@mixin user-profile-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-transform: uppercase;
  background-color: $info;
}

.userProfile {
  position: relative;
}

.internalProfileAvatar {
  @include user-profile-avatar;

  font-size: 18px;
  font-weight: 600;
  height: 48px;
  width: 48px;
  min-width: 48px;
  margin-right: 14px;
}

.triggerProfileAvatar {
  @include user-profile-avatar;

  position: relative;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 500;
  height: 48px;
  width: 48px;
  min-width: 48px;

  &::after {
    content: "";
    background-image: url('../../../assets/images/icons/down-arrow.svg');
    background-repeat: no-repeat;
    position: absolute;
    right: -15px;
    top: calc(50% - 4px);
    display: block;
    height: 10px;
    width: 8px;
    padding: 5px;
  }
}

.userProfileMenu {
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  top: 60px;
  right: 5px;
  z-index: -1;
  width: 0;
  background-color: white;
  box-sizing: border-box;
  transition: all .3s ease-in-out;
}

.userProfileMenuItem {
  padding: 20px 12px;
  border: 1px solid #E8E9EB;

  a {
    color: $darkgray;
  }

  transform: scale(0);
  z-index: -1;

  .text-dark {
    a {
      color: $dark;

      &:hover {
        color: lighten($dark, 15%);
      }
    }
  }

  & + .userProfileMenuItem {
    border-top: none;
  }

  .userProfileMenuItemLink {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    font-size: 0.9375rem;
    color: $darkgray;

    & > * + span {
      margin-left: 16px;
    }

    svg path {
      fill: $darkgray;
    }

    &:hover {
      border-radius: 5px;
      background-color: #F8F8F8;

      svg path {
        fill: $darkgray;
      }
    }
  }
}

.userProfileMenu.open {
  opacity: 1;
  width: 290px;
  z-index: 2;

  .userProfileMenuItem {
    animation-name: ciZoomIn;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
}
