@import 'assets/styles/variables';

.internalContent {
  background-color: #fff;
  flex-grow: 1;
  margin-left: 1px;
  margin-right: 1px;
  height: calc(100vh - 101px); // 100vh - topbarHeight - margin
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
