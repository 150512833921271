@import 'assets/styles/variables';

.backdrop {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
}

.open {
  .backdrop {
    display: block;
  }

  .floatingMenu {
    width: 224px;
    opacity: 1;
    z-index: 2;

    .floatingMenuItem {
      opacity: 1;
      display: flex;
    }

    .floatingMenuSeparator {
      opacity: 1;
    }
  }
}

.floatingMenu {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  box-sizing: border-box;
  width: 0px;
  border: 1px solid #E8E9EB;
  padding: 12px 0;
  opacity: 0;
  z-index: -1;
  transition: opacity .3s ease-in-out, width .3s ease-in-out;

  .floatingMenuSeparator {
    background-color: #E8E9EB;
    height: 1px;
    width: 100%;
    margin: 12px 0;
    opacity: 0;
  }

  .floatingMenuItem {
    display: flex;
    align-items: center;
    padding: 12px 28px;
    color: $darkgray;
    font-size: 15px;
    font-weight: 400;
    opacity: 0;
    background-color: transparent;
    width: 100%;
    border: none;

    &>*+span {
      margin-left: 16px;
    }

    &:hover {
      background-color: #F8F8F8;
    }
  }
}
