.badge {
  line-height: 1.5;

  &.info {
    background-color: #F2FBFF;
    color: #5AC8FA;
  }

  &.success {
    background-color: #F1FBF4;
    color: #20A144;
  }

  &.danger {
    background-color: #FFF7F2;
    color: #E56E19;
  }

  &.warning {
    background-color: #FFF8EB;
    color: #FFAA00;
  }
}
