@import 'assets/styles/variables';

.applicationInternalSidebar {
  background-color: $white;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  width: 20%;
  box-sizing: border-box;
}
