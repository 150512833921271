@import 'assets/styles/variables';

.applicationContainer {
  background-color: $white-secondary;
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}
