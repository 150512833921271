.main-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  & > .container-fluid {
    min-height: 100vh;

    & > .row {
      min-height: 100vh;
    }
  }
}

.container-cover {
  .right-col {
    .right-col__content {
      max-width: 765px;
    }
  }
}

.container-apps {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: 200px;
  justify-content: space-between;
  gap: 40px;
  padding: 3.75rem 4rem;
}

@include media-breakpoint-up(md) {
  .container-cover {
    .right-col {
      .right-col__content {
        margin-top: -20%;
      }
    }
  }
}
