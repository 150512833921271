.list-group {
  .list-group-item.list-group-item-action {
    font-size: 0.825rem;
  }

  &.list-group-flush {
    & > .list-group-item:first-child {
      border-top: 1px solid $list-group-border-color;
    }

    & > .list-group-item:last-child {
      border-bottom: 1px solid $list-group-border-color;
    }
  }

  &.list-group--draggable {
    .search {
      padding: 0;
      margin: 0;

      .input-group.input-group--gray input,
      .input-group.input-group--gray span {
        background-color: $white;
      }
    }

    .list-group-item {
      color: $headings-color;
      min-height: 40px;
      background-color: $white;
      border: none;
      border-radius: 0;

      &:hover, &.selected {
        border: 1px solid $orange;
      }

      & + .list-group-item {
        margin-top: 10px;
      }
    }
  }

  .close-list {
    cursor: pointer;
    float: right;
  }
}
