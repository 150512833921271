@import 'assets/styles/variables';

.applicationTopBarMenuItem {
  cursor: pointer;
  margin: 0 8px;

  path {
    fill: $secondary;
  }

  &:hover {
    path {
      fill: $primary;
    }
  }
}
